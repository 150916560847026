
import * as Components from "./Kiin"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "kiin"
}

