import React, { Component, PureComponent, useContext } from 'react';
import {NavLink, Link, Redirect} from 'react-router-dom'
import Carousel from 'nuka-carousel';

import Catering from './Catering'
import About from './About'
import Contact from './Contact'
// import Menu from './Menu'
import Kitchen from './Kitchen'
import Bakery from './Bakery'
import GiftCards from './GiftCards'
import Spirits from './Spirits'
import Delivery from './Delivery'
import './index.scss'
import './ThemeToggle/theme_night.scss'
import './wine.scss'
import ThemeContext from '../../Context/ThemeContext'

import {sections} from './sections'
import ThemeToggle from './ThemeToggle'
import CartBar from './CartBar'
export {CartBar as StickyTopBar}



export const customOrderAppPaths = ["/", "/menu", "/order"]

export const pages = [
  {path: "/order", label: "Order" },
  {path: "/menu", label: "Menu" },
  // {path: "/gift-cards", label: "Gift Cards", component: GiftCards },
  // {path: "https://thenewdealrestaurant.com/", external: true, label: "Sister Location" },

]

export class SiteNav extends PureComponent {
  render() {

    return (
    <nav className="pages-nav">
      <ul>
        {pages.map(({path, label, external, hidden, unlisted}) => {
          if (hidden || unlisted) { return null}
          return (
          <li>
            {external ?
              <a className="page-link" href={path}>{label}</a> :
              <NavLink className="page-link" exact to={path}>{label}</NavLink>}
          </li>
        )})}
      </ul>
      <ThemeToggle></ThemeToggle>
    </nav>
  )}
}

const quickLinks = [
  { to: '/order', label: 'order online'},
  { to: '/bakery', label: 'bakery'},
  { to: '/breakfast-lunch', label: 'marketplace'},
  { to: '/catering', label: 'catering'},
]

const foodImages = [
  // { url: "https://afag.imgix.net/tortas-mexico/meat.jpeg"},
  { url: "https://afag.imgix.net/western-wok/54-mixed-veggie.jpg"},
  { url: "https://afag.imgix.net/western-wok/28-orange-chicken-1.jpg"},
  { url: "https://afag.imgix.net/western-wok/27-kung-pao-chicken.jpg"},
  { url: "https://afag.imgix.net/western-wok/43-broccoli-beef.jpg"}
]

const news = [
  {
    url: "https://afag.imgix.net/romancing-the-bean-cafe/new-drink.jpg",
    text: `We are so excited to have our newest drink @drink.renude ‘s CHAGACCINO in the Café! It’s been a hit and has so many health benefits 🙏🏼 Stress-relief, Immune boosting, and Anti-Aging are a few of the health benefits this drink offers. Have it hot or cold, here or to-go and served with your choice of milk! `
  },
  {
    url: "https://afag.imgix.net/romancing-the-bean-cafe/t-shirt.jpg",
    text: `We’ve got you covered with Fall Gear from The Bean 🙏🏼❄️ Sweaters and T-Shirts are available in XS-XL sizes, make sure to get your’s before they run out! 👕`
  }
]




export function Header () {

    const {themeName} = useContext(ThemeContext)
    const heroUrl = (themeName === 'dark') ?
      "https://afag.imgix.net/tortas-mexico/meat.jpeg?w=500&h=500&fit=crop"
      :
     "https://afag.imgix.net/tortas-mexico/meat.jpeg?w=500"
    return (
      <div className="landing-page-wrapper">
        {/* <div className="banner">
        <img 
        style={{
          maxWidth: "500px",
          margin: "0.5em auto",
          display: "block"
        }}
        src="https://afag.imgix.net/kiin-thai-eatery/holiday-1.jpg?w=1000&fit=crop&auto=format" 
              alt="We are open over the holidays. Let us help you get a head start on your 2023 goals and dreams!" />
        </div> */}

        <div className="carousel-hero">
          <Carousel
            className="carousel"
            autoplay={false}
            slideWidth={1}
            // transitionMode={'scroll3d'}
            renderBottomCenterControls={()=> { return null}}
            wrapAround={true}
            >
              {/* <img src="https://afag.imgix.net/sidewalk-grill/hero-1.jpg?h=450&w=1000&fit=crop&auto=format"
                alt="assorted mediterraneon food on table"
               /> */}
            {/* <img src="https://afag.imgix.net/electric-lotus/hero.jpg?h=450&w=1000&fit=crop&auto=format"
              alt="indian cuisine on dishes"
             />
            <img src="https://afag.imgix.net/electric-lotus/hero-1.jpg?h=450&w=1000&fit=crop&auto=format"
              alt="indian spices"
             />
             <img src="https://afag.imgix.net/electric-lotus/hero.jpg?h=450&w=1000&fit=crop&auto=format"
               alt="indian cuisine on dishes"
              /> */}

            <img src="https://afag.imgix.net/kiin-thai-eatery/combo-3.jpg?h=500&w=1000&fit=crop&auto=format"
              alt="curry and egg plant"
             />
            <img src="https://afag.imgix.net/kiin-thai-eatery/shrimp-combo.jpg?h=500&w=1000&fit=crop&auto=format"
              alt="shrimps"
             />
            <img src="https://afag.imgix.net/kiin-thai-eatery/combo-1.jpg?h=500&w=1000&fit=crop&auto=format"
              alt="gyozas"
             />
            <img src="https://afag.imgix.net/kiin-thai-eatery/sweet.jpg?h=500&w=1000&fit=crop&auto=format"
              alt="dessert"
             />
            <img src="https://afag.imgix.net/kiin-thai-eatery/chicken-charcoal.jpg?h=500&w=1000&fit=crop&auto=format"
              alt="skewers"
             />
             {/* <img src="https://afag.imgix.net/sidewalk-grill/hero-1.jpg?h=450&w=1000&fit=crop&auto=format"
               alt="assorted mediterraneon food on table"
              /> */}
          </Carousel>
          <div className="hero-graphics">
          
            {/* <div className="logo-wrapper">
              <img
                className="slide-show-logo"
                src="https://afag.imgix.net/sotta-mediterranean/logo-white.png?w=800&auto=format" alt=""/>
            </div> */}
            <div className="info">
              {/* <Link to="/menu" className="hero-link">View Menu</Link> */}
              <Link to="/order" className="hero-link">Order Pickup / Delivery</Link>
              <br/>
              <span
                style={{
                  display: 'inline-block',
                  background: '#ff7a00',
                  padding: '0.5em 0.3em',
                }}
                className="hint">Open 7 days<br/>11am - 3pm<br/>4pm - 9pm</span>
              <br/>
              {/* <span className="hint">delivery within 3miles (no deliveries on Sunday)</span>
               */}
              
              <br/>
              {/* <span className="hint">5% cashback for orders over $30!</span> */}
              {/* <br/> */}
              <span className="hint">Dining Room Now Open!</span>
              {/* <br/> */}
              {/* <Link
                className="alt-ordering-link"
                href="/order">order via new website</Link> */}
              {/* <br/> */}
              {/* <a
                className="hero-link phone"
                href="+18185507800">818.550.7800</a> */}
            </div>

          </div>

        </div>

        {/* <div
          className="hero-banner">
          <div className="text">


          </div>

        </div> */}


        {/* <div className="menu">
          <div className="food-gallery">
            <div className="photo">
              <Link to="/menu">View Menu</Link>
            </div>
            {foodImages.map(({url}) => {
              return(
                <div className="photo">
                  <img src={`${url}?w=300&h=300&fit=crop`} alt=""/>
                </div>
              )
            })}
          </div>
        </div> */}

        {/* <div className="news-section">
          <div className="header">
            <h1>Posts</h1>
          </div>

          <div className="features">
            {news.map(({url, text}) => {
              return(
                <section className={"feature-section"}>
                  <div className="photo">
                    <img src={`${url}?w=500&h=500&fit=crop`} alt=""/>
                  </div>
                  <div className="content">

                    <div className="text">
                      <p>{text}</p>
                    </div>

                  </div>
                </section>
              )
            })}
          </div>
        </div> */}


        {/* <div className="features">

          {sections.map(({h1, sub, img, content, link, linkText, className=""}) => {
            return(
              <section className={"feature-section" + className}>
                <div className="photo">
                  <img src={img} alt=""/>
                </div>
                <div className="content">
                  <h3>{h1}</h3>
                  <div className="text">
                    <p>{content}</p>
                  </div>
                  <div className="more-content">
                    <Link className="link" to={link}>{linkText}</Link>
                  </div>
                </div>
              </section>
            )
          })}
        </div> */}



          
      </div>
    );

}


export class OrderingHeader extends PureComponent {
  render() {
    return (
      <div className="ordering-header">
        {/* <PoweredBy></PoweredBy> */}
      </div>
    );
  }
}

export function subMenuFilter (subMenu) {
  const tag = subMenu.tag
  if (!tag) {return true}

  if (tag === 'addons') {
    return false
  }

  const now = new Date()
  const day = now.getDay() // Sunday - Saturday : 0 - 6
  const hours = now.getHours() //0 to 23
  const minutes = now.getMinutes() // 0 to 59
  const isWeekday = [1,2,3,4,5].indexOf(day) !== -1
  const isLunch = (
    (hours >= 11) && (hours < 15)
  )
  const isDinner = (
    (hours >= 17) && (hours < 21)
  )

  // const showLunchMenu = isWeekday && isLunch

  const isLunchComboMenu = tag.indexOf('lunch') !== -1
  const isDinnerComboMenu = tag.indexOf('dinner') !== -1
   // m-f 11am-3pm
  if (isLunchComboMenu) {
    if (isLunch && isWeekday) { return true }
    return false
  }
  if (isDinnerComboMenu) {
    if (isDinner && isWeekday) { return true }
    return false
  }

  return true
}
